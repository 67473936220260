/*
  Execute `task` when the current event loop is completely finished.
  To delay a number of ms before executing the task, pass `delay`.
 */
export function queueTask(task: VoidFunction, delay = 0): void {
  if (task) {
    setTimeout(task, delay)
  }
}

const kMillisecondThreshold = 10

/*
  Sleep for the given number of milliseconds or seconds.
  If msOrSeconds < kMillisecondThreshould, it is considered seconds.
 */
export async function sleep(msOrSeconds: number): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const ms = msOrSeconds < kMillisecondThreshold ? msOrSeconds * 1000 : msOrSeconds
  await new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
