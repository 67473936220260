<template>
  <div class="holo-arrow-up"></div>
</template>

<script lang="ts"></script>

<style scoped>
.holo-arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid;
}
</style>
