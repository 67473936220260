import { createHash } from 'crypto'
import type { MagicUserMetadata } from 'magic-sdk'
import isEmail from 'validator/lib/isEmail'

export function validateEmail(email: string): boolean | unknown {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return isEmail(email)
}

// Ensure non blocking ports when testing
const HHA_PORT: number =
  process.env.NODE_ENV === 'test'
    ? Number(process.env.VUE_APP_HHA_PORT) + 1
    : Number(process.env.VUE_APP_HHA_PORT)

export const HHA_DOMAIN = HHA_PORT
  ? `http://localhost:${HHA_PORT}`
  : `${window.location.protocol}//${window.location.host}`

export async function timeoutAsync(ms: number): Promise<void> {
  return new Promise((_resolve, reject) => {
    setTimeout(
      reject,
      ms,
      `Timeout Error. Failed to request magic link within the expected ${
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        ms / 1000
      } second interval. Please check your credentials and try again.`
    )
  })
}

export function getPersistedUser(): MagicUserMetadata | null {
  const userData = localStorage.getItem('user')

  if (userData) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(userData)
  }

  return null
}

export function updatePersistedUser(newUserData: string): void {
  localStorage.setItem('user', newUserData)
}

export function digestData(data: string): string {
  return createHash('sha512').update(data, 'utf8').digest('base64')
}
